import { useEffect, useState } from "react";

/**
 * useMatchMedia
 *
 * usage:
 *   const matches = useMatchMedia("(min-width: 900px)")
 *   matches will be true or false
 *
 * the format of the string is important, eg, needs ()'s
 * see https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 * @param  {String} media : media query to match
 * @return {Boolean} true if it matches, false if it doesn't
 */
export default function useMatchMedia(media) {
  const [matches, setMatches] = useState(false);

  // define mediaQueryList inside effect because of server rendering/hydration
  // we need to render again when the client loads
  useEffect(() => {
    const mediaQueryList = window.matchMedia(media);
    const handleMatchChange = (event) => setMatches(event.matches);

    setMatches(mediaQueryList.matches);
    mediaQueryList.addListener(handleMatchChange);

    return () => {
      mediaQueryList.removeListener(handleMatchChange);
    };
  }, [media]);

  return matches;
}
