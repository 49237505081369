import "aos/dist/aos.css";

import AOS from "aos";
import { useEffect } from "react";

export default function useAOS() {
  useEffect(() => {
    const isAOSInitialized =
      document.querySelector("body").dataset.aosEasing !== undefined;

    if (!isAOSInitialized) {
      //default options, can be overridden using data attribute on element
      AOS.init({ duration: 500, easing: "ease", once: true, offset: 300 });
    }
  }, []);
}
