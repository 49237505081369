import { graphql, useStaticQuery } from "gatsby";

// eslint-disable-next-line sort-imports
import extractSiteNavigation from "./extractSiteNavigation.js";

export default function useSiteNavigation() {
  const contentfulData = useStaticQuery(query);

  return extractSiteNavigation(contentfulData);
}

const query = graphql`
  {
    contentfulSiteNavigation {
      navigationItems {
        ...Page
      }
    }
  }
`;

// const sections = [
//   {
//     title: "About",
//     subSections: [
//       { title: "Partners" },
//       { title: "Timeline" },
//       { title: "Contact" },
//     ],
//   },
//   {
//     title: "Policy Platform",
//     subSections: [
//       { title: "Equity and Accountability" },
//       { title: "Restorative Justice" },
//       { title: "Rebuild Communities" },
//     ],
//   },
//   {
//     title: "Community Resources",
//     subSections: [
//       { title: "Equity and Accountability" },
//       { title: "Restorative Justice" },
//       { title: "Rebuild Communities" },
//     ],
//   },
//   {
//     title: "Get Involved",
//     subSections: [
//       { title: "Equity and Accountability" },
//       { title: "Restorative Justice" },
//       { title: "Rebuild Communities" },
//     ],
//   },
// ];
