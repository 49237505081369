import "./container.scss";

import { node, string } from "prop-types";

import cx from "classnames";

const propTypes = {
  className: string,
  left: node,
  right: node,
};

const Container7030 = (props) => {
  const { left, right, className, ...otherProps } = props;
  return (
    <div
      className={cx("container container--70-30", className)}
      {...otherProps}
    >
      <div className="container__col container__col--70 container__col--70-left">
        {left}
      </div>
      <div className="container__col container__col--30 container__col--30-right">
        {right}
      </div>
    </div>
  );
};

Container7030.propTypes = propTypes;
export default Container7030;
