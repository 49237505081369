import "./mobile-header.scss";

import { arrayOf, shape, string } from "prop-types";

import AnimateHeight from "react-animate-height";
import Container100 from "../Container/Container100.jsx";
import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import Donate from "../Donate/Donate.jsx";
import Logo from "../Logo/Logo.jsx";
import Modal from "../Modal/Modal.jsx";
import SocialAccounts from "./SocialAccounts.jsx";
import cx from "classnames";
import { useState } from "react";

const propTypes = {
  sections: arrayOf(
    shape({
      title: string,
      subSections: arrayOf(
        shape({
          title: string,
        }),
      ),
    }),
  ),
};

const MobileHeader = (props) => {
  const { sections } = props;
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Container100 className="mobile-header__header">
        <Logo />
        <button
          className="mobile-header__hamburger-btn"
          onClick={() => setIsModalOpen(true)}
          type="button"
        >
          <span>Menu</span> {menuIcon}
        </button>
      </Container100>
      <Modal
        className="mobile-header__modal"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <div className="mobile-header__menu">
          <Container100 className="mobile-header__header">
            <Logo />
            <button type="button" onClick={() => setIsModalOpen(false)}>
              Close {closeIcon}
            </button>
          </Container100>

          <nav className="mobile-header__nav" aria-label="Primary Navigation">
            {sections.map((section) => {
              const { title, subSections } = section;
              const sectionId = title;
              return subSections ? (
                <div key={title} className="mobile-header__accordion-section">
                  <button
                    className={cx("mobile-header__accordion-btn", {
                      "mobile-header__accordion-btn--active":
                        activeAccordion === sectionId,
                    })}
                    onClick={() => {
                      activeAccordion === sectionId
                        ? setActiveAccordion(null)
                        : setActiveAccordion(sectionId);
                    }}
                    type="button"
                  >
                    <span>{chevronDownIcon}</span> {title}
                  </button>
                  <AnimateHeight
                    height={activeAccordion === sectionId ? "auto" : 0}
                    duration={500}
                  >
                    <ul className="mobile-header__accordion-list">
                      <li key={section.title}>
                        <ContentfulLink
                          type="internal"
                          className="mobile-header__link"
                          to={section.path}
                        >
                          {section.title}
                        </ContentfulLink>
                      </li>
                      {subSections &&
                        subSections.map((subSection) => {
                          return (
                            <li key={subSection.title}>
                              <ContentfulLink
                                type="internal"
                                className="mobile-header__link"
                                to={subSection.path}
                              >
                                {subSection.title}
                              </ContentfulLink>
                            </li>
                          );
                        })}
                    </ul>
                  </AnimateHeight>
                </div>
              ) : (
                <ContentfulLink
                  key={title}
                  type="internal"
                  className="mobile-header__navlink"
                  to={section.path}
                >
                  {title}
                </ContentfulLink>
              );
            })}
          </nav>
          <div className="mobile-header__footer">
            <Donate className="mobile-header__donate-link" />
            <SocialAccounts />
          </div>
        </div>
      </Modal>
    </>
  );
};

MobileHeader.propTypes = propTypes;
export default MobileHeader;

const chevronDownIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevron-down"
  >
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

const menuIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-menu"
  >
    <line x1="3" y1="12" x2="21" y2="12"></line>
    <line x1="3" y1="6" x2="21" y2="6"></line>
    <line x1="3" y1="18" x2="21" y2="18"></line>
  </svg>
);

const closeIcon = (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-x"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);
