import "./global-header.scss";

import { array, string } from "prop-types";

import DesktopHeader from "./DesktopHeader.jsx";
import MobileHeader from "./MobileHeader.jsx";
import cx from "classnames";
import useMatchMedia from "../../common/useMatchMedia.js";
import useScrollYDirection from "../../common/useScrollYDirection.js";

const propTypes = {
  sections: array, // will be validated by DesktopHeader/MobileHeader
  theme: string,
};

const GlobalHeader = (props) => {
  const { sections, theme } = props;

  const [scrollYDirection, scrollYPosition] = useScrollYDirection();

  const isDesktop = useMatchMedia("(min-width: 1170px)"); // matches $max-content-width in _breakpoints.scss

  return (
    <header
      className={cx("global-header", {
        [`global-header--${theme}`]: theme && scrollYPosition <= 0,
        "global-header--scrolled": scrollYPosition > 0,
        "global-header--scrolling-down": scrollYDirection === "down",
        "global-header--scrolling-up": scrollYDirection === "up",
      })}
    >
      {isDesktop ? (
        <DesktopHeader sections={sections} theme={theme} />
      ) : (
        <MobileHeader sections={sections} />
      )}
    </header>
  );
};

GlobalHeader.propTypes = propTypes;
export default GlobalHeader;
