import * as R from "ramda";

import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { staticPages } from "./staticPages.js";

const ContentfulSiteMapContext = React.createContext();

export function useContentfulSiteMap() {
  return useContext(ContentfulSiteMapContext);
}

export const ContentfulSiteMapProvider = (props) => {
  const contentfulData = useStaticQuery(query);

  // String -> Page -> [Page]
  const processPage = R.curry((parentPath, breadCrumbs, page) => {
    const { id, slug, title, subPages, pageContent } = page;

    const path = `${parentPath}/${slug}`; // TODO make sure slashes aren't doubled;
    const newBreadCrumbs = [...breadCrumbs, id];

    return [
      {
        id,
        slug,
        path,
        title,
        pageContent,
        breadCrumbs: [...newBreadCrumbs],
      },
      ...processPages(path, [...newBreadCrumbs], subPages),
    ];
  });

  // Object -> String -> [Pages] -> Array
  const processPages = R.curry((parentPath, breadCrumbs, pages) => {
    return R.compose(
      R.unnest,
      R.map(processPage(parentPath, breadCrumbs)),
      R.defaultTo([])
    )(pages);
  });

  const siteMap = R.compose(
    R.indexBy(R.prop("id")),
    //add in staticPages

    R.concat(staticPages),

    processPages("", [""]),
    R.map(R.prop("node")),
    R.flatten,
    R.paths([
      ["allContentfulPage", "edges"],
      ["allContentfulEvent", "edges"],
    ]),
    R.defaultTo({})
  )(contentfulData);

  return <ContentfulSiteMapContext.Provider value={siteMap} {...props} />;
};

const query = graphql`
  {
    # filter is required to avoid overwriting of pages
    allContentfulPage(filter: { isTopLevel: { eq: true } }) {
      edges {
        node {
          id
          ...Page
        }
      }
    }
    allContentfulEvent {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
  }
`;
