import "./dropdown.scss";

import { node, string } from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";

// https://hackernoon.com/its-a-focus-trap-699a04d66fb5
import FocusLock from "react-focus-lock";
import cx from "classnames";
import debounce from "lodash.debounce";
import useClickAway from "../../common/useClickAway.js";

const propTypes = {
  button: node.isRequired,
  children: node.isRequired,
  className: string,
};

const Dropdown = (props) => {
  const { button, children, className, ...otherProps } = props;

  const [isOpen, setOpen] = useState(false);

  const containerRef = useRef();
  const dropdownRef = useRef();

  useClickAway({ isOpen, setOpen, ref: containerRef });

  // re-measure after a resize has happened
  useEffect(() => {
    const handleResize = debounce((e) => {
      if (isOpen) {
        setOpen(false);
        window.requestAnimationFrame(() => {
          setOpen(true);
        });
      }
    }, 100);

    window.addEventListener("resize", handleResize);

    return () => {
      handleResize.cancel();
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen]);

  // ajust the dropdown left/right if it's off the screen
  // (eg, on the right side of the viewport)
  const dropdownPositions = useMemo(() => {
    // if this is the first render, or the dropdown is closed, return the defaults
    // (this ensures that dropdownRect always starts with the same dimensions)
    if (!dropdownRef.current || !containerRef.current || !isOpen) {
      return { left: 0, right: "auto" };
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
    const dropdownRect = dropdownRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    // if the screen is narrower than the dropdown, make it the viewport width
    if (dropdownRect.width > window.innerWidth) {
      return {
        width: "auto",
        minWidth: 0,
        left: -1 * containerRect.left,
        right: -1 * (window.innerWidth - containerRect.right),
      };
    }
    // if the right is off the screen
    else if (dropdownRect.right && dropdownRect.right > window.innerWidth) {
      // if right aligning would make it overflow left, nudge it instead
      if (containerRect.right < dropdownRect.width) {
        return { left: window.innerWidth - dropdownRect.right };
      }
      // otherwise, just right align
      else {
        return { left: "auto", right: 0 };
      }
    }
    // otherwise, left align it by default
    else {
      return { left: 0, right: "auto" };
    }
  }, [isOpen]);

  return (
    <div
      className={cx("dropdown", className, {
        "dropdown--is-open": isOpen,
      })}
      ref={containerRef}
      {...otherProps}
    >
      <button
        type="button"
        className="dropdown__button"
        onClick={() => setOpen((o) => !o)}
      >
        {button}
        <div className="dropdown__arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chevron-down"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </div>
      </button>
      <div
        className="dropdown__dropdown"
        ref={dropdownRef}
        role="dialog"
        style={{
          position: "absolute",
          ...dropdownPositions,
        }}
      >
        <FocusLock disabled={!isOpen} returnFocus={true}>
          {children}
        </FocusLock>
      </div>
    </div>
  );
};

Dropdown.propTypes = propTypes;
export default Dropdown;
