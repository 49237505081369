import * as R from "ramda";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default R.compose(
  (props) => {
    const {image, ...other} = props;
    return image && (<GatsbyImage {...other} image={image} />);
  },
  R.applySpec({
    alt: R.propOr("", "title"),
    // description: R.prop('description'),
    image: R.compose(getImage, R.prop("gatsbyImageData")),
  }),
  R.defaultTo({}),
);
