import "./spinner-button.scss";

import { bool, node, string } from "prop-types";

import Button from "../Button/Button.jsx";
import Spinner from "./Spinner.jsx";
import cx from "classnames";

const propTypes = {
  children: node,
  className: string,
  showSpinner: bool,
};

const SpinnerButton = (props) => {
  const { children, className, showSpinner, ...otherProps } = props;
  return (
    <Button
      {...otherProps}
      className={cx("spinner-button", className, {
        "spinner-button--show-spinner": showSpinner,
      })}
    >
      {children}
      <span className="spinner-button__spinner">
        <Spinner />
      </span>
    </Button>
  );
};

SpinnerButton.propTypes = propTypes;
export default SpinnerButton;
