import "./sign-up.scss";

import { useEffect, useState } from "react";

import PlusSigns from "../PlusSigns/PlusSigns.jsx";
import ResponseModal from "../ResponseModal/ResponseModal.jsx";
import SpinnerButton from "../Button/SpinnerButton.jsx";
import { node } from "prop-types";
import useAOS from "../../common/useAOS.js";
import { useForm } from "react-hook-form";

const propTypes = {
  title: node,
  subtitle: node,
};

// https://github.com/jquense/yup/blob/master/src/string.ts#L8-L9
const emailRegex =
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

const phoneRegex =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;

const SignUp = (props) => {
  const { title, subtitle } = props;

  useAOS();

  const [token, setToken] = useState(null);
  const [responseModal, setResponseModal] = useState(false);
  const [response, setResponse] = useState({});

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: new Headers(),
      redirect: "follow",
    };
    fetch(
      "https://connect.civilrights.org/site/CRConsAPI?method=getLoginUrl&v=1.0&response_format=json&api_key=lcchr",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setToken(result.getLoginUrlResponse.JSESSIONID);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const formProps = useForm({
    defaultValues: {
      interests: [],
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid, submitCount },
  } = formProps;

  const onSubmit = (data) => {
    const bodyParams = new URLSearchParams();

    //required params
    bodyParams.append("method", "create");
    bodyParams.append("v", "1.0");
    bodyParams.append("response_format", "json");
    bodyParams.append("api_key", "lcchr");
    bodyParams.append("auth", token);
    bodyParams.append("first_name", data.firstName);
    bodyParams.append("last_name", data.lastName);
    bodyParams.append("primary_email", data.email);
    bodyParams.append("sub_source", "vision for justice sign up form.");
    bodyParams.append("email.accepts_email", "true");
    //justice interest group
    bodyParams.append("add_interest_ids", "1062");

    //optional params
    data.mobileNumber && bodyParams.append("mobile_phone", data.mobileNumber);
    data.zipCode && bodyParams.append("primary_address.zip", data.zipCode);

    fetch("https://connect.civilrights.org/site/CRConsAPI", {
      method: "POST",
      headers: new Headers().append("Content-Type", "application/json"),
      redirect: "follow",
      body: bodyParams,
    })
      .then((response) => response.json())
      .then((json) => {
        reset();
        setResponse(json);
        setResponseModal(true);
      })
      .catch((e) => setResponse("error"));
  };

  const renderError = (name) =>
    errors?.[name]?.message && (
      <div className="form__error">
        {xIcon} {errors?.[name]?.message}
      </div>
    );

  return (
    <div className="sign-up js-sign-up">
      <div data-aos="fade-down" className="sign-up__content">
        {title && (
          <h2 className="sign-up__title">
            <strong>{title}</strong>
          </h2>
        )}
        {subtitle && <div className="sign-up__subtitle">{subtitle}</div>}

        <form className="sign-up__form form" onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={isSubmitting}>
            <div className="sign-up__text-fields">
              <div className="sign-up__field">
                <label>
                  <div className="sign-up__label">
                    First Name * {renderError("firstName")}
                  </div>
                  <input
                    type="text"
                    {...register("firstName", {
                      required: "First Name is a required field",
                    })}
                  />
                </label>
              </div>
              <div className="sign-up__field">
                <label>
                  <div className="sign-up__label">
                    Last Name * {renderError("lastName")}
                  </div>
                  <input
                    type="text"
                    {...register("lastName", {
                      required: "Last Name is a required field",
                    })}
                  />
                </label>
              </div>
              <div className="sign-up__field">
                <label>
                  <div className="sign-up__label">
                    Mobile Number {renderError("mobileNumber")}
                  </div>
                  <input
                    type="text"
                    {...register("mobileNumber", {
                      pattern: {
                        value: phoneRegex,
                        message: "Please provide a valid phone number",
                      },
                    })}
                  />
                </label>
              </div>
              <div className="sign-up__field">
                <label>
                  <div className="sign-up__label">
                    Zip Code * {renderError("zipCode")}
                  </div>
                  <input
                    type="text"
                    {...register("zipCode", {
                      required: "Zip Code is a required field",
                      pattern: {
                        value: zipCodeRegex,
                        message: "Please provide a valid zip code",
                      },
                    })}
                  />
                </label>
              </div>
              <div className="sign-up__field sign-up__field--email">
                <label>
                  <div className="sign-up__label">
                    Email Address * {renderError("email")}
                  </div>
                  <input
                    // type="email" messes with validation
                    type="text"
                    {...register("email", {
                      required: "Email is a required field",
                      pattern: {
                        value: emailRegex,
                        message: "Please provide a valid email",
                      },
                    })}
                  />
                </label>
              </div>
            </div>
            <div className="sign-up__button">
              <SpinnerButton
                type="submit"
                className="btn btn--big"
                // only disable when invalid after the first submit attempt
                disabled={isSubmitting || (submitCount > 0 && !isValid)}
                showSpinner={isSubmitting}
              >
                Sign up for updates
              </SpinnerButton>
            </div>
          </fieldset>
        </form>
      </div>
      <ResponseModal
        type="signup"
        isOpen={responseModal}
        onClose={() => setResponseModal(false)}
        response={response}
      />
      <div className="container container--100">
        <PlusSigns isWhite={true} moveUp={true} />
      </div>
    </div>
  );
};

SignUp.propTypes = propTypes;
export default SignUp;

const xIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <path
      fill="#E47572"
      fillRule="nonzero"
      d="M9.52 10.5l.98-.98L8.48 7.5l2.02-2.02-.98-.98L7.5 6.52 5.48 4.5l-.98.98L6.52 7.5 4.5 9.52l.98.98L7.5 8.48l2.02 2.02zM7.5 0C11.65 0 15 3.35 15 7.5S11.65 15 7.5 15A7.48 7.48 0 010 7.5C0 3.35 3.35 0 7.5 0z"
    />
  </svg>
);
