import * as R from "ramda";

import { node, oneOf, string } from "prop-types";

/**
 * extractLink
 * To be used with <ContentfulLink {...link} />
 *
 * !! important !! use the ...Link fragment above!
 *
 * {
 *   link {
 *     ...Link
 *   }
 * }
 */
export default R.compose(
  R.cond([
    [
      R.propEq("__typename", "ContentfulLinkExternal"),
      R.applySpec({
        children: R.prop("text"),
        to: R.prop("url"),
        title: R.prop("description"),
        type: () => "external",
      }),
    ],
    [
      R.propEq("__typename", "ContentfulLinkInternal"),
      R.applySpec({
        children: R.prop("text"),
        pageId: R.path(["page", "id"]),
        title: R.prop("text"),
        type: () => "internal",
      }),
    ],
    [
      R.propEq("__typename", "ContentfulLinkMedia"),
      R.applySpec({
        children: R.prop("text"),
        to: R.path(["media", "file", "url"]),
        title: R.prop("description"),
        type: () => "media",
      }),
    ],
  ]),
  R.defaultTo({}),
);

export const linkFields = {
  children: node,
  to: string,
  title: string,
  type: oneOf(["media", "external", "internal"]),
};
