import "./social-accounts.scss";

import cx from "classnames";
import { string } from "prop-types";
import { useMemo } from "react";

const propTypes = {
  className: string,
};

const SocialAccounts = (props) => {
  const accounts = useMemo(
    () => [
      {
        href: "https://www.facebook.com/civilandhumanrights/",
        icon: facebookIcon,
      },
      {
        href: "https://twitter.com/civilrightsorg",
        icon: twitterIcon,
      },
      {
        href: "https://www.instagram.com/civilrightsorg/",
        icon: instagramIcon,
      },
    ],
    [],
  );

  return (
    <div className={cx("social-accounts container")}>
      {accounts.map((account, i) => {
        const { icon, href } = account;
        return (
          <a
            key={i}
            className="social-accounts__link icon"
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            {icon}
          </a>
        );
      })}
    </div>
  );
};

SocialAccounts.propTypes = propTypes;
export default SocialAccounts;

const facebookIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0v24h24V0H0zm16 7h-1.923C13.461 7 13 7.252 13 7.889V9h3l-.239 3H13v8h-3v-8H8V9h2V7.077C10 5.055 11.064 4 13.461 4H16v3z"
      fill="currentColor"
    />
  </svg>
);

const twitterIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M.861 0v24h24V0h-24zm18.862 9.237c.208 4.617-3.235 9.765-9.33 9.765a9.286 9.286 0 01-5.032-1.475 6.605 6.605 0 004.86-1.359 3.285 3.285 0 01-3.066-2.28 3.3 3.3 0 001.482-.056c-1.579-.317-2.668-1.739-2.633-3.26.442.246.95.394 1.486.411A3.289 3.289 0 016.474 6.6a9.32 9.32 0 006.766 3.43 3.285 3.285 0 015.594-2.993 6.568 6.568 0 002.085-.796 3.292 3.292 0 01-1.443 1.816 6.578 6.578 0 001.885-.517 6.707 6.707 0 01-1.638 1.697z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

const instagramIcon = (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path fill="#fff" d="M0 0h24v24H0z" />
    <path d="M8 3C5.242 3 3 5.242 3 8v8c0 2.758 2.242 5 5 5h8c2.758 0 5-2.242 5-5V8c0-2.758-2.242-5-5-5zm0 2h8c1.652 0 3 1.348 3 3v8c0 1.652-1.348 3-3 3H8c-1.652 0-3-1.348-3-3V8c0-1.652 1.348-3 3-3zm9 1c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm-5 1c-2.758 0-5 2.242-5 5s2.242 5 5 5 5-2.242 5-5-2.242-5-5-5zm0 2c1.652 0 3 1.348 3 3s-1.348 3-3 3-3-1.348-3-3 1.348-3 3-3zm0 0" />
  </svg>
);
