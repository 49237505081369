import "./response-modal.scss";

import { bool, func, object, string } from "prop-types";

import Modal from "../Modal/Modal.jsx";

const propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  response: object.isRequired,
  type: string.isRequired,
};

const ResponseModal = (props) => {
  const { isOpen, onClose, response, type } = props;

  const renderContactResponse = () => {
    return response?.submitSurveyResponse?.success ? (
      <>
        <h2>
          <strong>Thank you!</strong>
        </h2>
        <p>Thank you for your interest!</p>
      </>
    ) : (
      <>
        <h2>
          <strong>Error</strong>
        </h2>
        <p>Error response not recorded!</p>
      </>
    );
  };
  const renderSignUpResponse = () => {
    return response?.createConsResponse?.message ===
      "The new constituent was successfully added." ? (
      <>
        <h2>
          <strong>Thank you!</strong>
        </h2>
        <p>Thank you for your interest!</p>
      </>
    ) : (
      <>
        <h2>
          <strong>
            {response &&
              response.errorResponse &&
              response.errorResponse.message ===
                "An error occurred while attempting to create this constituent because constituent Profiles were found that contained matching information." &&
              "You have already subscribed!"}
          </strong>
        </h2>
        {response &&
          response.errorResponse &&
          response.errorResponse.message && (
            <p>{response.errorResponse.message}</p>
          )}
      </>
    );
  };

  return (
    <Modal className="response-modal" isOpen={isOpen} onClose={onClose}>
      <div className="response-modal__content">
        {type === "contact" && renderContactResponse()}
        {type === "signup" && renderSignUpResponse()}
      </div>
    </Modal>
  );
};

ResponseModal.propTypes = propTypes;
export default ResponseModal;
