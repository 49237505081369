import "../../scss/global.scss";

import * as R from "ramda";

import { node, number, string } from "prop-types";

import { ContentfulSiteMapProvider } from "../../common/contentfulSiteMap.jsx";
import GlobalFooter from "../GlobalFooter/GlobalFooter.jsx";
import GlobalHeader from "../GlobalHeader/GlobalHeader.jsx";
import Head from "../Head/Head.jsx";
import cx from "classnames";
import useSiteNavigation from "../../common/useSiteNavigation.js";

const propTypes = {
  pageTitle: string,
  pageDescription: string,
  pageImage: string,
  children: node.isRequired,
  theme: string,
  className: string,
  gradient: number,
};

const Page = (props) => {
  const {
    pageTitle,
    pageDescription,
    pageImage,
    children,
    theme = "default",
    className,
    gradient,
  } = props;

  const isDetailPageEquity = className === "detail-page-equity";
  const sections = useSiteNavigation();

  return (
    <ContentfulSiteMapProvider>
      <Head
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageImage={pageImage}
      />
      <a className="skip-link" href="#main-content">
        Skip to main content
      </a>
      <GlobalHeader
        theme={isDetailPageEquity ? "reverted" : theme}
        sections={sections}
      />
      <main
        id="main-content"
        className={cx(
          "page",
          { "page--gradient": !R.isNil(gradient) },
          className,
        )}
        data-gradient-index={gradient}
      >
        {children}
      </main>
      <GlobalFooter sections={sections} footerContent={""} />
    </ContentfulSiteMapProvider>
  );
};

Page.propTypes = propTypes;
export default Page;
