import "./container.scss";

import { node, string } from "prop-types";

import cx from "classnames";

const propTypes = {
  children: node,
  className: string,
};

const ContainerBleed = (props) => {
  const { children, className, ...otherProps } = props;
  return (
    <div
      {...otherProps}
      className={cx("container", "container--bleed", className)}
    >
      {children}
    </div>
  );
};

ContainerBleed.propTypes = propTypes;
export default ContainerBleed;
