import "./global-footer.scss";
import * as R from "ramda";
import { graphql, useStaticQuery } from "gatsby";
// eslint-disable-next-line sort-imports
import { arrayOf, object, shape, string } from "prop-types";
import Container100 from "../Container/Container100.jsx";
import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import Donate from "../Donate/Donate.jsx";
import LOGO from './Frame_2202.svg'
import { and } from "ramda";
const propTypes = {
  sections: arrayOf(
    shape({
      title: string,
      subSections: arrayOf(
        shape({
          title: string,
          link: object,
        })
      ),
    })
  ),
};


const GlobalFooter = (props) => {
  const { sections } = props;
  const { contentfulFooterContent } = useStaticQuery(query);
  const { footerCopy, footerLinks, footerLogos, footerCopyright } =
    contentfulFooterContent;
  const year = new Date().getFullYear();
  
  const FullLink = (props) => {
    return (
      <>
        <ContentfulLink
          key={props.v.url}
          type={R.contains("mail", props.v.url) ? "internal" : "external"}
          to={props.v.url}
          title={props.v.description}
        >
          {props.v.text}
        </ContentfulLink>
        {props.i < footerLinks.length - 1 && (
          <span aria-hidden="true"> | </span>
        )}
      </>
    );
  };
  return (
    <footer className="global-footer container container--has-background">
      <Container100>
        <div className="global-footer__wrapper">
          <nav aria-label="Footer Navigation" className="global-footer__nav">
            {sections.map((section, i, array) => {
              const { title, path, subSections } = section;

              return (
                <div className="global-footer__col" key={title}>
                  <div className="global-footer__col-title">
                    <ContentfulLink type="internal" to={path}>
                      {title}
                    </ContentfulLink>
                  </div>
                  <ul className="global-footer__list">
                    {subSections &&
                      subSections.map((subSection) => (
                        <li key={subSection.title}>
                          <ContentfulLink type="internal" to={subSection.path}>
                            {subSection.title}
                          </ContentfulLink>
                        </li>
                      ))}
                  </ul>
                </div>
              );
            })}
            <div className="global-footer__col" key="donate">
              <div className="global-footer__donate">
                <Donate />
              </div>
            </div>
          </nav>
          <div className="global-footer__copyright">
            <div className="global-footer__logo-wrapper">
              {footerLogos.map((i) => (
                <div style={{display:"flex"}}>
                  <div key={JSON.stringify(i)} className="global-footer__logo">
                    <img
                      src={i.asset.file.url}
                      height={i.asset.file.details.image.height}
                      width={i.asset.file.details.image.width}
                      alt={i.asset.description}
                    />
                  </div>
                  <div key={JSON.stringify(i)} className="global-footer__logo">
                    <img
                      src={LOGO}
                      height={i.asset.file.details.image.height }
                      width={i.asset.file.details.image.width}
                      alt={i.asset.description}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div>
              <div>{footerCopy}</div>
              <div>
                {footerLinks &&
                  footerLinks.map((v, i) => <FullLink v={v} i={i} key={i} />)}
              </div>
              <div>&copy; {`${year} ${footerCopyright}`}</div>
            </div>
          </div>
        </div>
      </Container100>
    </footer>
  );
};

GlobalFooter.propTypes = propTypes;
export default GlobalFooter;

const query = graphql`
  {
    contentfulFooterContent {
      id
      footerCopy
      footerLinks {
        id
        url
        text
        description
      }
      footerLogos {
        id
        asset {
          file {
            url
            details {
              size
              image {
                height
                width
              }
            }
          }
        }
      }
      footerCopyright
    }
  }
`;
