import * as R from "ramda";

// const extractSubPages = R.curry((page) => extractNavigationItems(page));

const extractNavigationItem = (parentPath, item) => {
  return {
    title: item.title,
    path: `${parentPath}/${item.slug}`,
    subSections: item.subPages
      ? item.subPages.map((subPage) =>
          extractNavigationItem(`${parentPath}/${item.slug}`, subPage)
        )
      : null,
  };
};

export default R.compose(
  R.map((data) => extractNavigationItem("", data)),
  R.path(["contentfulSiteNavigation", "navigationItems"]),
  R.defaultTo({})
);
