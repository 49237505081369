import { node, string } from "prop-types";

import cx from "classnames";

const propTypes = {
  className: string,
  children: node,
};

const Button = (props) => {
  const { className, children, ...otherProps } = props;
  return (
    <button {...otherProps} className={cx("btn", className)}>
      <span>{children}</span>
    </button>
  );
};

Button.propTypes = propTypes;
export default Button;
