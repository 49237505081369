import "./container.scss";

import { node, string } from "prop-types";

import cx from "classnames";

const propTypes = {
  as: string,
  className: string,
  left: node,
  right: node,
};

const Container6040 = (props) => {
  const { left, right, className, ...otherProps } = props;

  return (
    <div
      className={cx("container container--60-40", className)}
      {...otherProps}
    >
      <div className="container__col container__col--60 container__col--60-left">
        {left}
      </div>
      <div className="container__col container__col--40 container__col--40-right">
        {right}
      </div>
    </div>
  );
};

Container6040.propTypes = propTypes;
export default Container6040;
