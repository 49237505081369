import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import cx from "classnames";
import { string } from "prop-types";

const propTypes = {
  className: string,
};

const Donate = (props) => {
  const { className, ...otherProps } = props;

  return (
    <ContentfulLink
      className={cx("btn btn--small", className)}
      to="https://connect.civilrights.org/site/Donation2?df_id=1620&mfc_pref=T&1620.donation=form1"
      type="external"
      {...otherProps}
    >
      Donate
    </ContentfulLink>
  );
};

Donate.propTypes = propTypes;
export default Donate;
