import * as R from "ramda";
import * as types from "@contentful/rich-text-types";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Container3070 from "../components/Container/Container3070.jsx";
import Container5050 from "../components/Container/Container5050.jsx";
import Container6040 from "../components/Container/Container6040.jsx";
import Container7030 from "../components/Container/Container7030.jsx";
import ContentfulLink from "../components/ContentfulLink/ContentfulLink.jsx";
import extractLink from "./extractLink.js";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const { BLOCKS, INLINES } = types;

/**
 * extractRichText will render rich text AND embedded entries/assets
 * https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
 * @param  {Object} node graphql node { raw, references }
 * @return {Node} React node
 */
const extractRichText = (node) => {
  if (node) {
    return renderRichText(node, {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (entry) => {
          const { target } = entry.data;

          if (!target) {
            console.warn(
              "no target!",
              R.over(R.lensProp("raw"), JSON.parse)(node),
              entry,
            );
            return "Error block!";
          }

          switch (target.__typename) {
            case "ContentfulLinkExternal":
            case "ContentfulLinkMedia":
            case "ContentfulLinkInternal": {
              const link = extractLink(target);
              return (
                <div>
                  <ContentfulLink className="btn btn--big" {...link} />
                </div>
              );
            }
            case "ContentfulTwoUp": {
              const left = extractRichText(target.left);
              const right = extractRichText(target.right);

              switch (target.split) {
                case "50-50":
                  return <Container5050 left={left} right={right} />;
                case "70-30":
                  return <Container7030 left={left} right={right} />;
                case "30-70":
                  return <Container3070 left={left} right={right} />;
                case "60-40":
                  return <Container6040 left={left} right={right} />;
              }
              break;
            }
          }

          return "";
        },
        [INLINES.EMBEDDED_ENTRY]: (entry) => {
          const { target } = entry.data;

          if (!target) {
            console.warn(
              "no target!",
              R.over(R.lensProp("raw"), JSON.parse)(node),
              entry,
            );
            return "Error Inline!";
          }

          switch (target.__typename) {
            case "ContentfulLinkExternal":
            case "ContentfulLinkMedia":
            case "ContentfulLinkInternal": {
              const link = extractLink(entry.data.target);
              return <ContentfulLink {...link} />;
            }
          }

          return "";
        },
        [BLOCKS.EMBEDDED_ASSET]: (asset) => {
          const { target } = asset.data;

          if (!target) {
            console.warn(
              "no target!",
              R.over(R.lensProp("raw"), JSON.parse)(node),
              asset,
            );
            return "Error Inline!";
          }

          return (
            <GatsbyImage
              image={getImage(target.gatsbyImageData)}
              alt={target.title || ""}
            />
          );
        },
        [INLINES.ENTRY_HYPERLINK]: (entry) => {
          const link = extractLink(entry.data.target);
          return <ContentfulLink {...link} children={entry.content[0].value} />;
        },
        [INLINES.HYPERLINK]: (entry) => {
          return (
            <ContentfulLink
              type="internal"
              to={entry?.data?.uri}
              children={entry.content[0].value}
            />
          );
        },
      },
    });
  }

  return node;
};

export default extractRichText;
