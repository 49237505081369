import * as R from "ramda";

const aboutPageId = "080af153-9c68-5d4b-8d0b-30a2009b069d";
const aboutPage = {
  id: aboutPageId,
  slug: "our-story",
  path: "/our-story",
  title: "Our Story",
  breadCrumbs: ["", aboutPageId],
};

const takeActionPageId = "6df36c83-1f51-550a-917d-afc840640598";
const mapPageId = "a9a10325-390a-5d55-bcad-6ce6899e0098";
export const mapPage = {
  id: mapPageId,
  slug: "find-a-campaign",
  path: "/our-movement/find-a-campaign",
  title: "Find a Campaign",
  breadCrumbs: ["", takeActionPageId, mapPageId],
};

const partnersPageId = "7d5aee94-1557-531e-a0dc-b5fb2aa13c30";
const partnersPage = {
  id: partnersPageId,
  slug: "partners",
  path: "/our-story/partners",
  title: "Partners",
  breadCrumbs: ["", aboutPage.id, partnersPageId],
};

const contactPageId = "4fd83fb9-caa8-5d3b-8f64-f0dc5aa9e28f";
const contactPage = {
  id: contactPageId,
  slug: "contact",
  path: "/our-story/contact",
  title: "Contact",
  breadCrumbs: ["", aboutPage.id, contactPageId],
};

const timelinePageId = "73de8f53-c07d-57ee-b674-fa3fbe73f1bd";
export const timelinePage = {
  id: timelinePageId,
  slug: "timeline",
  path: "/timeline",
  title: "Timeline",
  breadCrumbs: ["", timelinePageId],
};

const eventsPageId = "0a03e606-f98d-5f8a-9641-fba2d635ea9d";
export const eventsPage = {
  id: eventsPageId,
  slug: "events",
  path: "/events",
  title: "Events",
  breadCrumbs: ["", eventsPageId],
};

//static pages to be added to site map
export const staticPages = [
  timelinePage,
  aboutPage,
  mapPage,
  partnersPage,
  contactPage,
  eventsPage
];
