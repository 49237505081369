import "./container.scss";

import { node, string } from "prop-types";

import cx from "classnames";

const propTypes = {
  children: node,
  className: string,
};

const ContainerFull = (props) => {
  const { children, className, ...otherProps } = props;

  return (
    <div className={cx("container container--100", className)} {...otherProps}>
      {children}
    </div>
  );
};

ContainerFull.propTypes = propTypes;
export default ContainerFull;
