import { graphql, useStaticQuery } from "gatsby";

import { Helmet } from "react-helmet";
import { string } from "prop-types";
import { useLocation } from "@reach/router";

const propTypes = {
  pageTitle: string,
  pageDescription: string,
  pageImage: string,
};

const defaultProps = {
  lang: "en",
  meta: [],
};

const Head = (props) => {
  const { pageTitle, pageDescription, pageImage } = props;

  const { pathname } = useLocation();

  const {
    contentfulSiteMetaData: { siteTitle, siteDescription },
  } = useStaticQuery(
    graphql`
      {
        contentfulSiteMetaData {
          siteTitle
          siteDescription
        }
      }
    `,
  );

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={siteTitle}
      titleTemplate={pageTitle && `${pageTitle} - %s`}
    >
      <meta
        name="description"
        content={pageDescription ? pageDescription : siteDescription}
      />
      {/* <meta name="image" content={seo.image} /> */}
      {/* {seo.url && <meta property="og:url" content={seo.url} />} */}
      {/* <meta property="og:type" content="website" />
      <meta property="og:title" content={siteTitle} />}
      <meta property="og:description" content={siteDescription} /> */}
      {/* {seo.image && <meta property="og:image" content={seo.image} />} */}
      {/* <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />} */}
    </Helmet>
  );
};

Head.propTypes = propTypes;
Head.defaultProps = defaultProps;
export default Head;
