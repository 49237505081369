import "./modal.scss";

import { bool, func, node, oneOfType, string } from "prop-types";
import { useEffect, useRef, useState } from "react";

// https://hackernoon.com/its-a-focus-trap-699a04d66fb5
import FocusLock from "react-focus-lock";
import ReactDOM from "react-dom";
import cx from "classnames";

const propTypes = {
  title: node,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  className: string,
  children: oneOfType([node, func]),
  closeIcon: node,
};

const defaultProps = {
  closeIcon: (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-x"
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  ),
};

const Modal = (props) => {
  const { isOpen, onClose, title, children, className, closeIcon, ...rest } =
    props;

  const overflowRef = useRef();

  // keep around the children so when we close, we can fade out with the content
  const childrenCache = useRef();

  const [modalContainer, setModalContainer] = useState();
  useEffect(() => {
    const container = document.createElement("div");
    container.setAttribute("class", "modal-container");
    document.querySelector("body").appendChild(container);

    setModalContainer(container);
    return () => {
      container.remove();
    };
  }, []);

  // if children is a funciton, render it with some state
  const content =
    typeof children === "function" ? children({ isOpen, title }) : children;

  if (content) {
    childrenCache.current = content;
  }

  // listen for esc if the modal is open
  useEffect(() => {
    const keydownHandler = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      // always open with the modal scrolled to the top
      if (overflowRef.current) {
        overflowRef.current.scrollTo(0, 0);
      }

      window.addEventListener("keydown", keydownHandler);
      return () => window.removeEventListener("keydown", keydownHandler);
    } else {
      window.removeEventListener("keydown", keydownHandler);
    }
  }, [isOpen, onClose]);

  useEffect(() => {
    // using classList.add/remove instead of classList.toggle so we make sure
    // we remove the modal-open class if the Modal gets unmounted when it's open!
    if (isOpen) {
      document.querySelector("body").classList.add("modal-open");

      return () => {
        document.querySelector("body").classList.remove("modal-open");
      };
    }
  }, [isOpen]);

  const classes = cx("modal", className, {
    "is-open": isOpen,
  });

  const modalNode = (
    <div className={classes} ref={overflowRef} {...rest}>
      <div className="modal__box-holder">
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div className="modal__overlay" onClick={onClose} />
        <FocusLock
          disabled={!isOpen}
          returnFocus={true}
          className="modal__box"
          role="dialog"
          aria-modal="true"
        >
          <div className="modal__title">
            {title}
            <button
              type="button"
              className="modal__close"
              onClick={onClose}
              aria-label="Close modal"
            >
              {closeIcon}
            </button>
          </div>
          <div className="modal__content">{childrenCache.current}</div>
        </FocusLock>
      </div>
    </div>
  );

  return modalContainer
    ? ReactDOM.createPortal(modalNode, modalContainer)
    : null;
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;
export default Modal;
