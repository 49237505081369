// Element -> Number
export function getOffsetTop(element) {
  if (!element) {
    return 0;
  }

  const { top } = element.getBoundingClientRect();
  const scrollTop = getScrollTop();
  return top + scrollTop;
}

// String -> Number
const getOffsetTopBySelector = (selector) => {
  try {
    return getOffsetTop(document.querySelector(selector));
  } catch (e) {
    // probably a bad selector
    console.error(`Failed to scroll to selector '${selector}'`);
  }
};

// cross browser schinanigans
export function getScrollTop() {
  return document.documentElement.scrollTop || document.body.scrollTop;
}

export function scrollTo(top) {
  // Check for IE 11
  if ("scrollBehavior" in document.documentElement.style) {
    window.scroll({
      top,
      left: 0,
      behavior: "smooth",
    });
  } else {
    window.scroll(0, top);
  }
}

export function scrollToSelector(selector) {
  scrollTo(getOffsetTopBySelector(selector));
}

export function scrollToElement(element) {
  scrollTo(getOffsetTop(element));
}
