import "./desktop-header.scss";
import {} from "prop-types";

import { arrayOf, shape, string } from "prop-types";

import Container100 from "../Container/Container100.jsx";
import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import Donate from "../Donate/Donate.jsx";
import Dropdown from "../Dropdown/Dropdown.jsx";
import Logo from "../Logo/Logo.jsx";
import SocialAccounts from "./SocialAccounts.jsx";
import { scrollToElement } from "../../common/scrollHelpers.js";
import { useMemo } from "react";

const propTypes = {
  sections: arrayOf(
    shape({
      title: string,
      subSections: arrayOf(
        shape({
          title: string,
        }),
      ),
    }),
  ),
  theme: string,
};

const DesktopHeader = (props) => {
  const { sections, theme } = props;

  const scrollToSignUp = useMemo(() => {
    const signUpEl = document.querySelector(".js-sign-up");

    if (signUpEl) {
      return (e) => {
        scrollToElement(signUpEl);
      };
    }
  }, []);

  return (
    <>
      <div className="desktop-header__social-outer">
        <Container100 className="desktop-header__social-inner">
          {scrollToSignUp && (
            <button
              type="button"
              className="desktop-header__social-accounts"
              onClick={scrollToSignUp}
            >
              Join our email list!
            </button>
          )}
          <a
            href="https://shopcivilrights.org/"
            className="desktop-header__social-accounts"
          >
            Visit our Shop
          </a>
          <SocialAccounts />
        </Container100>
      </div>
      <div className="desktop-header__bar-outer">
        <Container100 className="desktop-header__bar-inner">
          <Logo />
          <nav className="desktop-header__nav" aria-label="primary navigation">
            {sections.map((section) => {
              const { title, subSections } = section;
              return subSections ? (
                <Dropdown
                  key={title}
                  className="desktop-header__dropdown"
                  button={title}
                >
                  <ul className="desktop-header__dropdown-list">
                    <li key={section.title}>
                      <ContentfulLink
                        type="internal"
                        to={section.path}
                        className="desktop-header__link"
                      >
                        {section.title}
                      </ContentfulLink>
                    </li>
                    {subSections &&
                      subSections.map((subSection) => {
                        return (
                          <li key={subSection.title}>
                            <ContentfulLink
                              type="internal"
                              to={subSection.path}
                              className="desktop-header__link"
                            >
                              {subSection.title}
                            </ContentfulLink>
                          </li>
                        );
                      })}
                  </ul>
                </Dropdown>
              ) : (
                <ContentfulLink
                  key={title}
                  className="desktop-header__navlink"
                  type="internal"
                  to={section.path}
                >
                  {section.title}
                </ContentfulLink>
              );
            })}
          </nav>

          <Donate className="desktop-header__cta btn--ghost" />
        </Container100>
      </div>
    </>
  );
};

DesktopHeader.propTypes = propTypes;
export default DesktopHeader;
